export const LANG_DE_NAME = 'de';
export const LANG_DE_TRANS ={
  "FORM": {
  "TITLE_CONNEXION": "Connexion",
  "EMAIL":"E-Mail",
  "MODIFY_EMAIL":"Modifier l'email ?",
  "PASSWORD":"Mot de passe",
  "NEW_PASSWORD":"Nouveau mot de passe",
  "OLD_PASSWORD":"Ancien mot de passe",
  "REMEMBER_ME": "Se souvenir de moi",
  "NO_ACCOUNT_YET": "Vous n'avez pas encore de compte ?",
  "SIGN_UP_NOW": "Inscrivez vous !",
  "TITLE_SIGN_UP": "Inscription",
  "TITLE_EDIT": "Modifier mes informations",
  "ORGANISATION": "Nom de l'organisation",
  "DESCRIPTION": "Description",
  "PHONE": "Numero de téléphone",
  "LINK_FACEBOOK": "Lien Facebook",
  "LINK_TWITTER": "Lien Twitter",
  "LINK_INSTAGRAM": "Lien Instagram ",
  "LINK_WEBSITE": "Lien website ",
  "NOT_EMPTY": "Ce champ ne peut pas être vide.",
  "NOT_EMPTY_EMAIL": "Ce champ ne peut pas être vide et doit être sous la forme suivante exemple@mail.com",
  "NOT_EMPTY_PASSWORD": "Ce champ ne peut pas être vide et sa longeur doit dépassé 5 caractères.",
  "LINK_VIDEO": "Lien video",
  "CHOOSE_COUNTRY": "Pays",
  "COUNTRY": "Pays",
  "ACCEPT": "Accepter les ",
  "TERM_AND_CONDITIONS": "Accepter les termes et conditions",
  "NEWSLETTER": "Accepter la newsletter ?",
  "LOGIN": "Se connecter",
  "HAVE_ACCOUNT": "Vous avez déjà un compte ?",
  "SIGN_UP": "S'inscrire",
  "EDIT": "Sauvegarder les changements",
  "OR_SIGNUP_WITH": "Ou s'inscrire avec",
  "SIGININ_WITH": "Ou se connecter avec",
  "CANCEL_UPLOAD": "retirer l'image",
  "UPLOAD":"importer image",
  "DELETE_PICTURE":"supprimer la photo de profil",
  "LABEL_DATE_CREATION": "Date de création de l'organisation",
  "LABEL_CHOOSE_PICTURE": "Choisir votre photo de profile",
  "SEARCH": "Saisissez un nom, un prénom ou les 2",
  "CHOOSE_NATIONNALITY":"Nationnalité",
  "CHOOSE_FIGHTCARD":"Sélectionnez une fightcard",
  "CHOOSE_SEX":"Genre",
  "CHOOSE_WEIGHT": "Catégorie",
  "TYPE_FIGHT": "Type de combat",
  "LABEL_CHOOSE_NONE": "Aucun",
  "SUBMIT": "Enregistrer",
  "TITLE_CREATE_FIGHT_CARD":"Créez votre fightcard",
  "TITLE_UPDATE_FIGHT_CARD":"Modifier votre fightcard",
  "EVENT_NAME":"Nom de l'évènement",
  "PLACE":"Lieu de l'évènement",
  "DESCRIPTION_EVENT":"Description de l'évènement",
  "PRICE":"Prix de l'évènement",
  "REWARD":"Prime de combat",
  "MIN_REWARD":"Prime de combat minimum",
  "MAX_REWARD":"Prime de combat maximum",
  "SELECT_IMAGE":"Choisissez une image",
  "PUBLISHED":"Publiée",
  "TEXT_DELETE":"Voulez vous vraiment supprimer ",
  "FIGHTCARD_TITLE_FORM" : "Créez votre fightcard",
  "HIDE_IN_APP" : "Ne pas afficher dans l'application MMA Connection",
  "IS_ROOKIE": "Combat amateur",
  "ROOKIE": "Amateur",
  "IS_RESERVE" : "Combat de reserve",
  "SELECT_FIGHTERS" : "Choix des combattants",
  "FIGHT_INFORMATIONS" : "Informations principales",
  "FIGHTER" : "Combattant",
  "RESERVE_FIGHTER" : "Combattant de réserve",
  "YOUR_MESSAGE" : "Votre message",
  "SUBJECT" : "Sujet du message",
  "FIGHT_MIN" : "Combat min.",
  "EVENT_DATE" : "Date de l'évènement",
  "LABEL_SEX_MALE" : "Homme",
  "LABEL_SEX_FEMALE" : "Femme",
  "CATCHWEIGHT" : "Poids libre",
  "TITLE_PASSWORD": "Modifier le mot de passe",
  "CHANGE_PASSWORD": "Valider la modification du mot de passe",
  "VALIDATE_NEW_PASSWORD": "Valider le nouveau mot de passe",
  "MEDICAL_INFORMATIONS_TITLE": "Informations médicales",
  "COUNTERPART_TITLE": "Contres parties",
  "BLOOD_TEST": "Test sanguin (HIV,B,C)",
  "BRAIN_MRI": "IRM cerebral",
  "STRESS_TEST": "Test à l'effort",
  "EYE_TEST": "Fond d'oeil",
  "PREMIUM": "Prime",
  "HOTEL": "Hotel",
  "TRANSPORT": "Transport ",
  "MEAL": "Repas ",
  "ACCOMPANYING_NUMBER": "Nombre d'accompagnant pris en charge",
  "OPTIONAL": "Partie facultative",
  "FORGOTTEN_PASSWORD": "Mot de passe oublié",
  "LICENSE": "Licence",
  "LICENSE_DETAILS": "Informations sur les licenses",

 },
  "LABEL": {
    "AGE": "ans"
  },
  "NAVBAR": {
    "TITLE_NAVBAR": "MMA CONNEXION",
    "MY_ACCOUNT": "Mon Compte",
    "PASSWORD": "Mot de passe",
    "PROFILE": "Profil",
    "LOG_OUT": "Se déconnecter",
    "HOME": "Accueil",
    "COMBATTANTS": "Combattants",
    "MANAGERS" : "Managers",
    "MESSAGE": "Vos Messages",
    "FIGHT_CARD":"Fightcard",
    "CREATE_FIGHT_CARD":"Créer une fightcard",
    "FIGHT_CARD_LIST":"Vos fightcards"
  },
   "ERROR": {
     "TITLE" : "Erreur",
      "ERROR_SERVER": "Veuillez réessayez ulterieurement",
      "ERROR_MAIL": "Vérifier que votre email est valide et réessayez",
      "INVALID_MAIL": "Ce n'est pas une adresse e-mail valide",
      "REQUIRED_MAIL": "Veuillez saisir un e-mail",
      "REQUIRED_PASSWORD": "Veuillez saisir un mot de passe",
      "PASSWORD_TOO_SHORT": "Le mot de passe doit contenir au moins 5 caractères",
      "ERROR_CREDENTIALS": "Vérifier vos identifiants et réessayez",
      "ERROR_EMPTY_FIELDS": "Vérifier que vous avez bien remplie toutes les informations obligatoires.",
      "ERROR_TERMS_CONDITIONS_NOT_ACCEPTED": "Vous n'avez pas accepter les termes et conditions.",
      "ERROR_IMAGE_UPLOAD": "L'image n'a pas été ajoutée correctement",
      "NO_RESULT": "Aucun résultat",
      "FIELD_REQUIRED" : "Veuillez compléter ce champ.",
      "OUPS_MESSAGE" : "Oups ... une erreur est survenue ...",
      "ERROR_SERVER_TITLE" : "Service non disponible",
      "ERROR_SERVER_MESSAGE" : "L'application ne peut pas joindre le service.",
      "DISCONNECTED_TITLE" : "Déconnection",
      "DISCONNECTED_MESSAGE" : "Vous avez été deconnecté de MMA Connection.",
      "SENT_MESSAGE" : "Le message n'a pas pu être envoyé. Veuillez ré-essayer plus tard.",
      "SELECT_FIGHTCARD" : "Sélectionnez une fightcard",
      "SELECT_FIGHT" : "Sélectionnez un combat",
      "DELETE_FIGHTER_BEFORE_ADDING" : "Vous devez d'avord supprimer un combattant.",
      "PASSWORD_NOT_EQUAL": "Les mots de passes ne correspondent pas.",
      "USER_DATA_NOT_LOADED": "Les données de l\'utilisateur ne sont pas chargées",
      "IMAGE_NOT_DELETED": "L\'image n\'a pas été supprimée correctement",
      "ERROR_CHANGE_PASSWORD": 'Le mot de passe n\'a pas pu être modifié',
      "MANAGER": "Ce combattant n'a pas de manager",
   },
   "SUCCESS": {
      "SUCCESS_IMAGE_UPLOAD": "Image enregistrée abec succès.",
      "TITLE" : "Succès !",
      "FIGHT_ADDED" : "Combat créé avec succès.",
      "FIGHT_UPDATED" : "Combat mis à jour avec succès.",
      "FIGHTER_ADDED" : "Combattant ajouté avec succès.",
      "FIGHTER_DELETED" : "Combattant supprimé avec succès.",
      "MESSAGE_SENT" : "Message envoyé avec succès.",
      "FIGHTCARD_UPDATED" : "Votre Fight card à bien été modifiée",
      "ACCOUNT_UPDATED": "Votre compte à bien été enregistré",
      "IMAGE_DELETED": "L\'image a été supprimée correctement",
      "SUCCESS_CHANGE_PASSWORD": "Le mot de passe a été modifié avec succès",
      "SUCCESS_RESET_PASSWORD": "Un e-mail vous a été envoyé pour modifier votre mot de passe",
   },
   "TEXT":{
     "NO_FIGHTCARD" : "Créez votre fightcard en cliquant sur le bouton '+'",
     "NO_RESULTS" : "Aucun résultats",
     "NO_FIGHT" : "Aucun combat enregistré. Créez votre premier combat en cliquant sur le bouton '+'",
     "ADD_FIGHTER" : "Ajoutez directement les combattants de votre choix, ou laissez les postuler puis valider les candidatures.",
     "DELETE_FIGHTER_TITLE" : "Supprimer ce combattant ?",
     "DELETE_FIGHTER_TEXT" : "Etes-vous sûres de vouloir supprimer ce combattant ?",
     "DELETE_FIGHT_TITLE" : "Supprimer ce combat ?",
     "DELETE_FIGHT_TEXT" : "Etes-vous sûres de vouloir supprimer ce combat ?",
     "UPDATE_FIGHT_TITLE" : "Modification du combat",
     "NEW_MESSAGE" : "Nouveau message",
     "FROM_FIGHT" : "de ce combat ?",
     "MORE_CRITERIA" : "+ de critères",
     "APPLICANTS" : "POSTULANTS",
     "NOAPPLICANTS" : "Aucun postulant",
     "ADD_TO_FIGHT" : "Ajouter au combat ?",
     "ADD_ANOTHER_FIGHT" : "Voulez-vous ajouter un autre combat ?",
     "ABOUT_FIGHT" : "A propos de ce combat",
     "ABOUT_FIGHT_NONE" : "Aucune information complémentaire renseignée",
     "FIGHTERS" : "Combattants",
     "REWARD" : "Récompense",
     "NOT_SPECIFIED" : "Non renseigné",
     "HIS_FIGHTERS" : "SES COMBATTANTS",
     "ADD_OTHER_FIGHT" : "Votre combat a été créé avec succès. Voulez-vous ajouter un autre combat ?",
     "MANAGER" : "MANAGER",
     "UNAVAILABLE" : "Indisponible",
     "UNAVAILABILITY_DATES" : "Indisponibilités",
     "TODAY" : "Aujourd'hui",
     "OR" : "Ou",
     "MEDICAL_INFORMATIONS" : "Pour pouvoir combattre le combattant doit fournir :",
     "COUNTERPART" : "Vous prenez à votre charge :",
     "RESET_PASSWORD": "Entrez votre adresse email pour réinitialiser votre mot de passe.",
     "DISCUSSION": "Discussion",
     "NEW_CONVERSATION": "Nouvelle conversation",
     "TITLE_FIND_SOMEONE" : "Trouver une personne",
     "SEND_MESSAGE_TO": "Envoyer un nouveau message à : ",
     "MANAGERS": "Managers",
     "CONTACTS": "Contacts",
     "START_CONVERSATION": "Démarrez ou reprenez une conversation avec un manager ou un combattant",
     "SHOW_MORE": "Afficher plus",
     "CHOOSE_SMILEY": "Choisissez un smiley",
     "GET_STARTED_FREE": "Commencer gratuitement ",
     "START_TO_EXPLORE": "Commencez à explorer notre produit gratuitement.",
     "NEW_MESSAGE_TEXT": "Vous avez un nouveau message !",
     "NEW_MAIL": "Nouveau mail",
   },
   "PAGE": {
      "TITLE_FIGHT_CARD_LIST": "Vos Fight Cards",
      "TITILE_FIGHT_LIST":"Votre Fight Card",
      "TITLE_ADD_FIGHTER_TO_FIGHT":"Ajout d'un combattant",
      "TITLE_DELETE_FIGHTER_FROM_FIGHT": "Retirer un combatant d'un combat",
      "TITLE_CREATE_FIGHT" : "Créez votre combat"
   },
   "FIGHTCARD":{
    "NO_FIGTER_YET": "Pas de combattant",
    "FIGHT": "Combat"
  },
  "BUTTON": {
    "LOGIN": "Se connecter",
    "LOG_OUT": "Se deconnecter",
    "SEARCH": "Rechercher",
    "RESET": "RàZ ?",
    "RESET_FILTER" : "Effacer les filtres",
    "ADD_TO_FIGHT":"Ajouter à un combat",
    "REMOVE_FROM_FIGHT":"Supprimer du combat",
    "CONTACT_MANAGER":"Contacter le manager",
    "CONTACT_MANAGER_AND_FIGHTER":"Contacter le manager et le combatant",
    "MODIFY_FIGHT_CARD":"Modifer cette fight card",
    "ADD_FIGHTER_TO_FIGHT":"Ajouter un combattant",
    "DELETE":"Supprimer",
    "EDIT" : "Modifier",
    "CLOSE": "Fermer",
    "ADD_FIGHT" : "Ajouter un combat",
    "SHOW_FIGHTLIST" : "Liste des combats",
    "SAVE" : "Enregistrer",
    "YES" : "Oui",
    "NO" : "Non",
    "ADD" : "Ajouter",
    "CONTACT" : "Contacter",
    "SEND_MAIL" : "Envoyer un mail",
    "SEND_MESSAGE" : "Envoyer un message",
    "SEE_FIGHT" : "Voir les combats",
    "REPLACE_FIGHTER" : "Remplacer",
    "CANCEL" : "Annuler",
    "SEND" : "Envoyer",
    "MAIL_MANAGER": "Envoyer un e-mail au manager",
    "MAIL_MANAGER_AND_FIGHTER": "Envoyer un e-mail au manager et au combatant",
    "MESSAGE_MANAGER": "Envoyer un message au manager",
    "MESSAGE_FIGHTER": "Envoyer un message au combatant",
  },
  "timeAgo": {
    "now" : "Maintenant",
    "day" : "j",
    "minute" : "min",
    "hour" : "h",
    "yesterday" : "Hier",
    "tomorrow" : "Demain",
  },
  "dates" : {
    "monthShortNames" : "Jan, Fév, Mar, Avr, Mai, Jui, Jui, Aoû, Sep, Oct, Nov, Déc",
    "jan" : "Janvier",
    "feb" : "Février.",
    "march" : "Mars",
    "apr" : "Avril",
    "may" : "Mai",
    "jun" : "Juin",
    "jully" : "Juillet",
    "aug" : "Août",
    "sept" : "Septembre",
    "oct" : "Octobre",
    "nov" : "Novembre",
    "dec" : "Décembre"
  },

}
