import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class MobileSubscriptionService {

  constructor(
    private httpClient: HttpClient,
    private globals: Globals
  ) { }

  getListSubscriptions(bearer):Observable<any>{
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' +bearer, 'Cache-Control': ['no-cache', 'no-store'], 'Pragma': 'no-cache', 'Expires': '0' });
    return this.httpClient.get(this.globals.baseUrl+'tarefsubscription',{headers : headers});
  }
  activatePaypalSubscription(bearer) : Observable<any>{
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' +bearer, 'Cache-Control': ['no-cache', 'no-store'], 'Pragma': 'no-cache', 'Expires': '0' });
    return this.httpClient.post(this.globals.baseUrl+'subscription/activate',null,{headers : headers});
  }
  getMe(bearer):Observable<any>{
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' +bearer, 'Cache-Control': ['no-cache', 'no-store'], 'Pragma': 'no-cache', 'Expires': '0' });
    return this.httpClient.get(this.globals.baseUrl + 'me',{headers : headers});
  }
  suspendSubscription(bearer):Observable<any>{
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' +bearer, 'Cache-Control': ['no-cache', 'no-store'], 'Pragma': 'no-cache', 'Expires': '0' });
    return this.httpClient.post(this.globals.baseUrl+'subscription/suspend',null,{headers : headers});
  }
}
