export const LANG_FR_NAME = 'fr';
export const LANG_FR_TRANS ={
    "FORM": {
  	"TITLE_CONNEXION": "Connexion",
  	"EMAIL":"E-Mail",
    "actualEmailRequired" : "Votre e-mail actuel",
    "newEmailRequired" : "Votre nouvel e-mail",
    "MODIFY_EMAIL":"Modifier l'email ?",
  	"PASSWORD":"Mot de passe",
    "ACTUAL_PASSWORD":"Mot de passe actuel",
    "NEW_PASSWORD":"Nouveau mot de passe",
    "OLD_PASSWORD":"Ancien mot de passe",
  	"REMEMBER_ME": "Se souvenir de moi",
  	"NO_ACCOUNT_YET": "Vous n'avez pas encore de compte ?",
  	"SIGN_UP_NOW": "Inscrivez vous !",
  	"TITLE_SIGN_UP": "Créer votre compte MMA Connection",
    "TITLE_EDIT": "Modifier mes informations",
  	"ORGANISATION": "Nom de votre organisation",
    "BILLING_ADDRESS" : "Adresse de facturation",
  	"DESCRIPTION": "Description",
  	"PHONE": "Numero de téléphone",
  	"LINK_FACEBOOK": "Lien Facebook (url)",
  	"LINK_TWITTER": "Lien Twitter (url)",
  	"LINK_INSTAGRAM": "Lien Instagram (url)",
  	"LINK_WEBSITE": "Site internet",
  	"NOT_EMPTY": "Ce champ ne peut pas être vide.",
  	"NOT_EMPTY_EMAIL": "Ce champ ne peut pas être vide et doit être sous la forme suivante exemple@mail.com",
  	"NOT_EMPTY_PASSWORD": "Ce champ ne peut pas être vide et sa longeur doit dépassé 5 caractères.",
  	"LINK_VIDEO": "Lien vidéo",
  	"CHOOSE_COUNTRY": "Pays",
  	"COUNTRY": "Pays",
  	"ACCEPT": "Accepter les ",
  	"TERM_AND_CONDITIONS": "Accepter les termes et conditions",
  	"NEWSLETTER": "Je veux recevoir la newsletter",
  	"LOGIN": "Se connecter",
  	"HAVE_ACCOUNT": "Vous avez déjà un compte ?",
  	"SIGN_UP": "S'inscrire",
    "EDIT": "Sauvegarder les changements",
  	"OR_SIGNUP_WITH": "Ou s'inscrire avec",
  	"SIGININ_WITH": "Ou se connecter avec",
  	"CANCEL_UPLOAD": "retirer l'image",
  	"UPLOAD":"importer image",
    "DELETE_PICTURE":"supprimer la photo de profil",
  	"LABEL_DATE_CREATION": "Date de création de l'organisation",
  	"LABEL_CHOOSE_PICTURE": "Choisir votre photo de profile",
  	"SEARCH": "Saisissez un nom, un prénom ou les 2",
  	"CHOOSE_NATIONNALITY":"Nationnalité",
    "CHOOSE_FIGHTCARD":"Sélectionnez une fightcard",
  	"CHOOSE_SEX":"Genre",
  	"CHOOSE_WEIGHT": "Catégorie",
    "TYPE_FIGHT": "Type de combat",
  	"LABEL_CHOOSE_NONE": "Aucun",
  	"SUBMIT": "Enregistrer",
  	"TITLE_CREATE_FIGHT_CARD":"Créez votre fightcard",
    "TITLE_UPDATE_FIGHT_CARD":"Modifier votre fightcard",
  	"EVENT_NAME":"Nom de l'évènement",
  	"PLACE":"Lieu de l'évènement",
  	"DESCRIPTION_EVENT":"Description de l'évènement",
  	"PRICE":"Prix de l'évènement",
    "REWARD":"Prime de combat",
    "MIN_REWARD":"Prime de combat minimum",
    "MAX_REWARD":"Prime de combat maximum",
  	"SELECT_IMAGE":"Ajouter une image",
  	"PUBLISHED":"Publiée",
  	"TEXT_DELETE":"Voulez vous vraiment supprimer ",
    "FIGHTCARD_TITLE_FORM" : "Créez votre fightcard",
    "HIDE_IN_APP" : "Ne pas afficher dans l'application MMA Connection",
    "IS_ROOKIE": "Combat amateur",
    "IS_PRO": "Combat professionnel",
    "FIGHT_LEVEL" : "Niveau de combat",
    "ROOKIE": "Amateur",
    "IS_RESERVE" : "Combat de reserve",
    "SELECT_FIGHTERS" : "Choix des combattants",
    "FIGHT_INFORMATIONS" : "Informations principales",
    "FIGHTER" : "Combattant",
    "RESERVE_FIGHTER" : "Combattant de réserve",
    "YOUR_MESSAGE" : "Votre message",
    "SUBJECT" : "Sujet du message",
    "FIGHT_MIN" : "Combat min.",
    "FIGHT_MAX" : "Combat max.",
    "VICTORY_MIN" : "Victoire(s) min.",
    "EVENT_DATE" : "Date de l'évènement",
    "LABEL_SEX_MALE" : "Homme",
    "LABEL_SEX_FEMALE" : "Femme",
    "CATCHWEIGHT" : "Catch weight",
    "TITLE_PASSWORD": "Modifier votre mot de passe",
    "CHANGE_PASSWORD": "Valider la modification du mot de passe",
    "VALIDATE_NEW_PASSWORD": "Re-saisissez votre nouveau mot de passe",
    "MEDICAL_INFORMATIONS_TITLE": "Informations médicales",
    "COUNTERPART_TITLE": "Contres parties",
    "BLOOD_TEST": "Test sanguin (HIV,B,C)",
    "BRAIN_MRI": "IRM cerebral",
    "STRESS_TEST": "Test à l'effort",
    "EYE_TEST": "Fond d'oeil",
    "ANGIO_IRM": "IRM angio cérébral",
    "ECG_TEST": "Electrocardiogramme (ECG)",
    "PREMIUM": "Prime",
    "HOTEL": "Hotel",
    "TRANSPORT": "Transport ",
    "MEAL": "Repas ",
    "ACCOMPANYING_NUMBER": "Nombre d'accompagnant pris en charge",
    "OPTIONAL": "Partie facultative",
    "FORGOTTEN_PASSWORD": "Mot de passe oublié",
    "LICENCE": "Licence",
    "LICENCE_DETAILS": "Informations sur les licenses",
    "contactSubject" : "Sujet du message",
    "contactMessage" : "Votre message",
    "nbMinWin" : "Nombre de victoire(s) minimum requise(s) pour pouvoir postuler",
    "nbMinFight" : "Nombre de combat(s) minimum requis(s) pour pouvoir postuler",
    "nbMaxFight" : "Nombre de combat(s) maximum requis(s) pour pouvoir postuler",
    "nbRound" : "Nombre de round",
    "timeRound" : "Temps des rounds",
    "timeRoundMinute" : "Temps des rounds (minutes)*",
    "timeRoundSeconde" : "Temps des rounds (secondes)*",
    "deleteFightCardMessage" : "Veuillez saisir un message qui sera envoyer aux managers et combattants inscrits à cet évènement",
    "TITLE_CHANGING_EMAIL" : "Modification de votre adresse e-mail",
    "SEARCH_CLUB" : "Club",
    "name" : "Nom",
    "firstname" : "Prénom",
    "nickname" : "Surnom",
    "nbVictories" : "Nb. victoire",
    "nbDefeat":"Nb. defaite",
    "nbDraw":"Nb. match nul",
    "birthDate" : "Date de naissance",
    "palmaresLink" : "Lien palmarès officiel (Tapology ou Sherdog)",
    "city" : "Ville",
    "height" : "Taille",
    "weight" : "Poids",
    "fightWeight" : "Poids de combat",
    "proFighter": "Combattant professionnel",
    "selectElement" : "Sélectionnez",
    "minAge" : "Age minimum pour particer au combat",
    "maxAge" : "Age maximum pour particer au combat",
    "PROMO" : "Code promotionnel",
    "EXP_ROOKIE" : "Expérience amateur",
    "CONTACT_US":"Nous contacter",
    "OPPONENT" : "Adversaire",
    "OTHER_INFORMATIONS" : "Autres informations",
    "MEDICAL_REQUIREMENTS" : "Exigences médicales",
    "CONTACT_INFORMATIONS" : "Pour vous contacter (facultatif)",
    "CONTACT_BY_EMAIL" : "J'accepte que les managers me contacte par e-mail via l'e-mail ci-dessous",
    "CONTACT_BY_PHONE" : "J'accepte que les managers me contacte par téléphone via le numéro ci-dessous",
    "CHOOSE_LEVEL": "Niveau",
    "CHOOSE_PRO_LEVEL": "Professionnel",
    "CHOOSE_AMATEUR_LEVEL": "Amateur",
    "CHOOSE_BOTH_LEVEL": "Amateur et professionnel",

   },
   "formControl" : {
     "emailRequired" : "Veuillez saisir votre e-mail",
     "actualEmailRequired" : "Veuillez saisir votre e-mail actuel",
     "newEmailRequired" : "Veuillez saisir votre nouvel e-mail",
     "enterValidEmail" : "Entrez une adresse e-mail valide",
     "passwordRequired" : "Veuillez saisir un mot de passe",
     "passworMinlength" : "Le mot de passe doit avoir au moins 5 caractères",
     "confirmPassword" : "confirmez votre mot de passe",
     "passwordAreNotEqual" : "Les mots de passes ne sont pas identiques",
     "acceptTerms" : "Vous devez accépter nos conditions d'utilisation",
     "nomRequired" : "Veuillez saisir votre nom",
     "sujetRequired" : "Veuillez saisir le sujet de votre message",
     "messageRequired" : "Veuillez saisir votre message",
     "fieldRequired" : "Veuillez compléter ce champ.",
     "telephoneError" : "Ce n'est pas un numéro de téléphone valide",
     "informationsRequired" : "Veuillez saisir vos informations",
     "hitDeleteWord" : "Veuillez saisir le mot DELETE dans ce champ",
     "minAge" : "L'âge minimum pour participer est 12 ans."
   },
    "LABEL": {
    	"AGE": "ans",
      "CANDIDATE" :"candidats"
    },
    "NAVBAR": {
      "TITLE_NAVBAR": "MMA CONNEXION",
      "MY_ACCOUNT": "Mon Compte",
      "CHANGE_EMAIL": "Modifier mon adresse e-mail",
      "PASSWORD": "Mot de passe",
      "PROFILE": "Profil",
  	  "LOG_OUT": "Se déconnecter",
  	  "HOME": "Accueil",
  	  "COMBATTANTS": "Combattants",
      "MANAGERS" : "Managers",
  	  "MESSAGE": "Vos Messages",
  	  "FIGHT_CARD":"Fight cards",
  	  "CREATE_FIGHT_CARD":"Créer une fight card",
  	  "FIGHT_CARD_LIST":"Vos fight cards",
      "CONTACT_US" : "Contacter MMA Connection",
      "BUG" : "Signaler un BUG",
      "MESSAGES" : "Messagerie",
      "MY_ORDERS" : "Mes paiements",
      "MY_SUBSCRIPTION" : "Mon abonnement"
    },
     "ERROR": {
       "TITLE" : "Erreur",
    		"ERROR_SERVER": "Veuillez réessayez ulterieurement",
     		"ERROR_MAIL": "Vérifier que votre email est valide et réessayez",
        "ERROR_MAIL_TAKEN" : "Adresse email déjà utilisée",
        "INVALID_MAIL": "Ce n'est pas une adresse e-mail valide",
        "REQUIRED_MAIL": "Veuillez saisir un e-mail",
        "REQUIRED_PASSWORD": "Veuillez saisir un mot de passe",
        "PASSWORD_TOO_SHORT": "Le mot de passe doit contenir au moins 5 caractères",
     		"ERROR_CREDENTIALS": "Vérifier vos identifiants et réessayez",
     		"ERROR_EMPTY_FIELDS": "Vérifier que vous avez bien remplie toutes les informations obligatoires.",
     		"ERROR_TERMS_CONDITIONS_NOT_ACCEPTED": "Vous n'avez pas accepter les termes et conditions.",
     		"ERROR_IMAGE_UPLOAD": "L'image n'a pas été ajoutée correctement",
     		"NO_RESULT": "Aucun résultat",
        "FIELD_REQUIRED" : "Veuillez compléter ce champ.",
        "OUPS_MESSAGE" : "Oups ... une erreur est survenue ...",
        "ERROR_SERVER_TITLE" : "Service non disponible",
        "ERROR_SERVER_MESSAGE" : "L'application ne peut pas joindre le service.",
        "DISCONNECTED_TITLE" : "Déconnection",
        "DISCONNECTED_MESSAGE" : "Vous avez été deconnecté de MMA Connection.",
        "SENT_MESSAGE" : "Le message n'a pas pu être envoyé. Veuillez ré-essayer plus tard.",
        "SELECT_FIGHTCARD" : "Sélectionnez une fightcard",
        "SELECT_FIGHT" : "Sélectionnez un combat",
        "DELETE_FIGHTER_BEFORE_ADDING" : "Vous devez d'avord supprimer un combattant.",
        "PASSWORD_NOT_EQUAL": "Les mots de passes ne correspondent pas.",
        "USER_DATA_NOT_LOADED": "Les données de l\'utilisateur ne sont pas chargées",
        "IMAGE_NOT_DELETED": "L\'image n\'a pas été supprimée correctement",
        "ACCOUNT_NOT_DELETED": "Le compte n\'a pas été supprimée correctement",
        "ERROR_CHANGE_PASSWORD": 'Le mot de passe n\'a pas pu être modifié',
        "MANAGER": "Ce combattant n'a pas de manager",
        "hitDeleteWord" : "Veuillez saisir le mot DELETE dans le champ de confirmation",
        "MEDICAL_INFORMATIONS_LOADING" : "Erreur : Impossible de charger les fichiers médicaux du combattant.",
        "selectSex" : "Veuillez sélectionner le sex.",
        "completeField" : "Veuillez renseigner ce champ",
        "fightcardPayment" : "Le paiement a échoué. Veuillez ré-essayer. Si l'erreur se reproduit, n'hésitez pas à contacter l'équipe MMA Connection.",
        "tooLate" : "Vous ne pouvez plus modifier la fightcard.",
        "ERROR_FIELD_NB_ROUND": "Veuillez compléter ce champ (minimum 1)",
        "ERROR_FIELD_TIME_ROUND": "Veuillez compléter ce champ (min 0, max 59)",
        "SUBSCRIPTION_LOADING" : "Nous ne parvenons pas à récupérer les informations de votre abonnement pour le moment. Veuillez ré-essayer dans un moment.",
        "orderCreationTxt" : "Une erreur s'est produite dans la création de votre facture. Veuillez ré-essayer.",
        "noTokenTxt" : "Vous ne disposez pas d'un jeton d'authentification valide pour accèder à cette page."
     },
     "SUCCESS": {
     		"SUCCESS_IMAGE_UPLOAD": "Image enregistrée avec succès.",
        "TITLE" : "Succès !",
        "FIGHT_ADDED" : "Combat créé avec succès.",
        "FIGHT_UPDATED" : "Combat mis à jour avec succès.",
        "FIGHTER_ADDED" : "Combattant ajouté avec succès.",
        "FIGHTER_DELETED" : "Combattant supprimé avec succès.",
        "MESSAGE_SENT" : "Message envoyé avec succès.",
        "FIGHTCARD_CREATED" : "Votre fightcard a été créé avec succès",
        "FIGHTCARD_CREATED_TXT" : "Pensez à publier votre fightcard afin de la rendre visible aux managers et combattants. Vous pouvez déjà commencer à créer vos combats.",
        "FIGHTCARD_UPDATED" : "Votre fightcard a bien été modifiée",
        "ACCOUNT_CREATED": "Votre compte à bien été créé. Vous allez être redirigé vers la page d'accueil.",
        "ACCOUNT_UPDATED": "Vos modifications ont été enregistré avec succés.",
        "IMAGE_DELETED": "L\'image a été supprimée correctement",
        "ACCOUNT_DELETED": "Le compte a été supprimée correctement",
        "SUCCESS_CHANGE_PASSWORD": "Le mot de passe a été modifié avec succès",
        "SUCCESS_RESET_PASSWORD": "Un e-mail vous a été envoyé pour modifier votre mot de passe. Pensez à vérifier vos SPAM ou indésirables.",
        "FIGHTER_REPLACEMENT" : 'Le combattant a été remplacé avec succès.',
        "contactSended" : "Message envoyée avec succès",
        "deletedFightCard" : "La fightcard a été supprimé avec succès",
        "publishedFightCard" : "La fightcard a été publiée avec succès",
        "unpublishedFightCard" : "La fightcard a été dépubliée avec succès",
        "emailChanging" : "Vous allez recevoir un e-mail de confirmation.",
        "fighterAdded" : "Combattant ajouté avec succès.",
        "fightcardPaid" : "Le paiement a été accepté. Votre fightcard est maintenant publiée et accessible à toute la communauté MMA Connection. Nous vous souhaitons un bel évènement.",
        "mails_sent" : "Les mails ont étés envoyés avec succès.",
        "managerSubscriptionTxt" : "Merci, votre abonnement débute dès maintenant. Vous pouvez fermer cette fenêtre en cliquant sur le bouton ci-dessus.",
        "unsubscribeTxt" : "Votre abonnement se termine dès maintenant. Vous pouvez le ré-activer à tout moment. Fermez cette fenêtre en cliquant sur le bouton ci-dessus."
     },
     "TEXT":{
       "WELCOME" : "Bienvenue dans votre gestion d'évènements professionnels",
       "NO_FIGHTCARD" : "Créez votre fightcard en cliquant sur le bouton '+'",
       "NO_RESULTS" : "Aucun résultat",
       "NO_FIGHTER_FOUND" : "Aucun résultat pour votre recherche.",
       "NO_FIGHT" : "Aucun combat enregistré. Créez votre premier combat en cliquant sur le bouton '+'",
       "ADD_FIGHTER" : "Ajoutez directement les combattants de votre choix, OU laissez les postuler.",
       "DELETE_FIGHTER_TITLE" : "Supprimer ce combattant ?",
       "DELETE_FIGHTER_TEXT" : "Etes-vous sûres de vouloir supprimer ce combattant ?",
       "DELETE_FIGHTCARD_TITLE" : "Supprimer cette fightcard ?",
       "DELETE_FIGHTCARD_TEXT" : "Etes-vous sûres de vouloir supprimer cette fightcard ? Cette action entraînera la suppression de tous les combats que vous avez créé sur cette fightcard. Cette action est irréversible.",
       "DELETE_FIGHT_TITLE" : "Supprimer ce combat ?",
       "DELETE_FIGHT_TEXT" : "Etes-vous sûres de vouloir supprimer ce combat ?",
       "UPDATE_FIGHT_TITLE" : "Modification du combat",
       "NEW_MESSAGE" : "Nouveau message",
       "FROM_FIGHT" : "de ce combat ?",
       "MORE_CRITERIA" : "+ de critères",
       "APPLICANTS" : "POSTULANTS",
       "NOAPPLICANTS" : "Aucun postulant",
       "ADD_TO_FIGHT" : "Ajouter au combat ?",
       "ADD_ANOTHER_FIGHT" : "Voulez-vous ajouter un autre combat ?",
       "ABOUT_FIGHT" : "A propos de ce combat",
       "ABOUT_FIGHT_NONE" : "Aucune information complémentaire renseignée",
       "FIGHTERS" : "Combattants",
       "REWARD" : "Récompense",
       "NOT_SPECIFIED" : "Non renseigné",
       "HIS_FIGHTERS" : "SES COMBATTANTS",
       "ADD_OTHER_FIGHT" : "Les informations de votre combat ont été mises à jour avec succès. Voulez-vous ajouter une nouvelle offre combat ?",
       "ADD_OTHER_INFORMATIONS" : "Votre combat a été créé avec succès. Voulez-vous ajouter des informations complémentaires (documents médicaux, prime, compensation, ...) ?",
       "MANAGER" : "MANAGER",
       "UNAVAILABLE" : "Indisponible",
       "UNAVAILABILITY_DATES" : "Indisponibilités",
       "TODAY" : "Aujourd'hui",
       "OR" : "Ou",
       "MEDICAL_INFORMATIONS" : "Pour pouvoir combattre le combattant doit fournir :",
       "COUNTERPART" : "Vous prenez à votre charge :",
       "RESET_PASSWORD": "Entrez votre adresse email pour réinitialiser votre mot de passe.",
       "DISCUSSION": "Discussion",
       "NEW_CONVERSATION": "Nouvelle conversation",
       "TITLE_FIND_SOMEONE" : "Trouver une personne",
       "SEND_MESSAGE_TO": "Envoyer un nouveau message à : ",
       "MANAGERS": "Managers",
       "YOUR_CONVERSATIONS": "Vos conversations",
       "START_CONVERSATION": "Démarrez ou reprenez une conversation avec un manager ou un combattant",
       "SHOW_MORE": "Afficher plus",
       "CHOOSE_SMILEY": "Choisissez un smiley",
       "GET_STARTED_FREE": "Commencer gratuitement ",
       "START_TO_EXPLORE": "Commencez à explorer notre produit gratuitement.",
       "NEW_MESSAGE_TEXT": "Vous avez un nouveau message !",
       "NEW_MAIL": "Nouveau mail",
       "CHECKING_PROFILE" : "Profil en cours de vérification",
       "NO_SHERDOG" : "Lien Sherdog/Tapology non ajouté",
       "deleteFighterToAdd" : "Vous devez annuler un combattant pour ajouter celui-ci.",
       "COMPLETE_FORM": "Veuillez compléter le formulaire ci-desous.",
       "CHANGING_EMAIL" : "Veuillez compléter le formulaire ci-dessous. Après validation, un e-mail sera envoyé à votre nouvelle adresse. Il contiendra un lien de validation valable 30 minutes. Votre nouvelle adresse e-mail sera prise en compte seulement après avoir cliqué sur le lien.",
       "PUBLISH_FIGHTCARD_TITLE" : "Publier la fightcard ",
       "PUBLISH_FIGHTCARD_TEXT" : "Etes-vous sûre de vouloir publier cette fightcard ? Les combattants et managers pourront accéder aux combats et y postuler. Vous pouvez la dépublier à tout moment.",
       "UNPUBLISH_FIGHTCARD_TITLE" : "Dépublier la fightcard ",
       "UNPUBLISH_FIGHTCARD_TEXT" : "Etes-vous sûre de vouloir dépublier cette fightcard ? Les combattants et managers ne pourront plus accéder aux combats ni y postuler, mais vous vous pourrez continuer à la modifier et vous pourrez la republier à tout moment.",
       "WARNING" : "Attention",
       "UNPUBLISHED_FIGHTCARD_TEXT" : "Fight card non publiée.",
       "LINK_SHERDOG" : "Palmarès Sherdog",
       "UNVERRIFIED_PROFILE" : "Identité non vérifiée",
       "VERRIFIED_PROFILE" : "Identité verifiée",
       "creatingProfile" : "Profil en cours de création",
       "MEDICAL_FILES_TITLE" : "Fichiers médicaux",
       "MEDICAL_FILES" : "Fichiers médicaux de",
       "certificat" : "Certificat médical de non contre indication de la pratique du mma en competition (-1 an)",
       "ecg" : "Electrocardiogramme ECG interprété (-1 an)",
       "irm" : "IRM cérébrale (-1 an)",
       "blood" : "Bilan sanguin (serologie hépatite B (HBsAg),serologie hépatite C (Anti HCV), HIV)",
       "eyes" : "Bilan ophtalmologique avec fond d'oeil (-2 ans)",
       "angioIrm" : "Angio IRM cérébrale (-1 an)",
       "testEffort" : "Test à l'effort",
       "fight_folder" : "Dossier de combat",
       "photo_folder" : "Photos",
       "completeAsteriskFields" : "Les champs avec une '*' sont obligatoires.",
       "noPhotos" : "Aucune photos",
       "noLicence" : "Aucune license",
       "updatedAt" : "Mis à jour le",
       "palmares" :"Palmarès officiel",
       'addFighter' : "Ajouter un combattant",
       "informations" : "Informations",
       "addFighterInfo" : "Vous avez la possibilité de créer le profil d'un combattant. Cependant, le profil sera actif uniquement lorsque le combattant en question créera son compte via l'application MMA Connection.",
       "paymentTitle" : "Payer et publier cette fightcard",
       "paymentTxt" : "Pour rendre votre fightcard accessible à toute la communauté MMA Connetion, veuillez régler le  montant indiqué, soit grâce à votre compte Paypal, soit par carte bancaire directement.",
       "amountToPay" : "Montant hors taxe de votre fightcard : ",
       "TRANSACTION_SAVING" : "Transaction en cours, veuillez patienter...",
       "completeBillingAddress" : "Veuillez compléter votre adresse de facturation avant de poursuivre votre paiement.",
       "SEND_MESSAGE_ALL_FIGHTERS_TITLE" : "Message groupé",
       "send_message_all_fighters_message" : "Envoyer un message à tous les combattants et managers de votre fightcard hors postulants.",
       "NO_FIGHT_IN_THIS_FIGHTCARD" : "Pas de combat dans cette fightcard",
       "MAX_FILES_SIZE" : "Taille des fichiers (max 15Mo)",
       "YOUR_SUBSCRIPTION" : "Votre abonnement actuel",
       "YOUR_PAYMENT_METHOD": "Moyen de paiement",
       "wantCancelSubscription" : "Vous voulez résilier votre abonnement ?",
       "wantActivateSubscription" : "Vous voulez réactiver votre abonnement ?",
       "noPayment" : "Aucun paiement à afficher",
       "subscribtionTxt" : "Votre abonnement débutera dès la validation de votre paiement. Vous pouvez l'arrêter à tout moment sans frais.",
       "selectPayementMode" : "Sélectionnez votre moyen de paiement",
       "currency" : "EUR",
       "no_subscription": "Vous n'avez aucun abonnement",
       "monthly_subscription" : "Forfait : Abonnement mensuel 49,90€ €HT soit 59,88 €TTC (premier mois gratuit)",
       "unsubscribe" : "Résilier votre abonnement",
       "unsubscribe_text" : "Êtes-vous sure de vouloir résilier votre abonnement ? Vous pourrez vous réabonner à tout moment.",
       "subscribedSince" : "Abonné depuis",
       "yourPayments" : "Vos paiements",
       "deleteAccountTitle" : "Supprimer le compte",
       "deleteAccountText" : "Etes-vous sûrs de vouloir supprimer votre compte ? Cette action entraînera la suppression de tous les combats et fightcards que vous avez créé sur ce compte. Cette action est irréversible.",
       "inputResult" : "Saisir le résultat",
       "deleteMessage" : "Êtes-vous sûrs de vouloir supprimer ce message ?",
       "deleteMessageTitle" : "Supprimer le message",
       "1_free_mounth" : "1 mois d'essai offert",
       "free_mounth" : "mois d'essai offerts",
       "per_mounth" : "par mois",
       "per_year" : "par an",
       "then" : 'puis',
       "cancel_without_charge" : "Résiliez à tout moment sans frais.",
       "subscribe" : "Abonnez-vous",
       "subscribe_text" : "En vous abonnant maintenant vous pourrez accéder à la totalité des fonctionnalités de MMA Connection !",
       "choose_mma_connection" : 'Pourquoi choisir MMA-Connection ?',
       "choose_mma_connection_title_1" : "Créez vos offres de combat",
       "choose_mma_connection_text_1" : "Créez vos événements et paramétrez vos offres de combats en quelques clics grâce a un outil pensé sur mesure pour les organisateurs de combats de MMA.",
       "choose_mma_connection_title_2" : "Recherchez des combattants",
       "choose_mma_connection_text_2" : "Recherchez les combattants dont vous avez besoin grâce à un système de filtres ultra avancé.",
       "choose_mma_connection_title_3" : "Recherchez des managers",
       "choose_mma_connection_text_3" : "Recherchez les managers en affinant votre recherche grâce à nos filtres.",
       "choose_mma_connection_title_4" : "Publiez vos fight-card",
       "choose_mma_connection_text_4" : "Diffusez vos offres de combats à toute la communauté. Ainsi vous gagnez du temps et de l'efficacité tout en augmentant votre visibilité.",
       "choose_mma_connection_title_5" : "Contactez qui vous voulez",
       "choose_mma_connection_text_5" : "Contactez via notre système de messagerie les combattants et managers de votre souhait",
       "choose_mma_connection_title_6" : "Accéder à de l'information vérifiée",
       "choose_mma_connection_text_6" : "Accédez à de l'information jusqu'à présent non disponible sur une seule et même plateforme. Disponibilité, management, mail, téléphone, poids de forme...",
       "fighter_informations" : "Informations du combattant",
       "candidate_list_title" : "Liste des candidats pour votre évènement",
       "pro_fighter" : "Combattant professionnel",
       "rookie_fighter" : "Combattant amateur",
       "EXP_ROOKIE" : "Expérience amateur",
       "reactivateSubscriptionManager":"Réactivez votre abonnement pour bénéficer de toutes les fonctionnalités de l'application MMA Connection.",
       "disabledOn" : "Désactivé le",
       "whyUseShortNotice" : "Pourquoi utiliser la fonction « Short notice »",
       "shortNoticeTxt1" : "Une annulation de dernière minute ?",
       "shortNoticeTxt2" : "Pas de panique, avec la solution « Short notice » de l’application",
       "shortNoticeTxt3" : "MMA Connection vous avez une solution simple rapide et efficace !",
       "shortNoticeTxt4" :"Compléter le formulaire ci-dessous et informez toute la communauté présente sur",
       "shortNoticeTxt5" :"l’application MMA Connection par mail, notification et sms !",
       "completeThisForm" :"Vos critères de sélection",
       "COMMING_SOON" : "Bientôt disponible.",
       "CandidateTxt" : "Ces combattants ont postulé pour combattre à votre évènement. Cliquez sur les catégories pour afficher les postulants.",
       "managedFighters" : "combattants managés",
       "yourFreePeriodExpireIn" : "Votre période d'essai se termine dans ",
       "noSubscriptionYet" : "Vous n'êtes pas abonnés",
      "freePeriodEnd" : "Votre période d'essai s'est terminée le ",
      "freeUseUntil" : "Vous bénéficiez d'une période d'essai jusqu'au",
      "selectSubscription":"Veuillez sélectionner votre abonnement",
      "ourOffers" : "Nos offres",
      "loadingInformations" : "Chargement de vos informations"
      },
      "WORD" : {
        "DAYS" : "jours"
      },
     "PAGE": {
     		"TITLE_FIGHT_CARD_LIST": "Vos Fight Cards",
        "TITLE_COMING_FIGHT_CARD_LIST": "Vos fight cards à venir",
        "TITLE_PASSED_FIGHT_CARD_LIST": "Vos fight cards passées",
     		"TITILE_FIGHT_LIST":"Votre Fight Card",
     		"TITLE_ADD_FIGHTER_TO_FIGHT":"Ajout d'un combattant",
     		"TITLE_DELETE_FIGHTER_FROM_FIGHT": "Retirer un combatant d'un combat",
        "TITLE_CREATE_FIGHT" : "Créez votre combat"
     },
     "FIGHTCARD":{
     	"NO_FIGTER_YET": "Pas de combattant",
     	"FIGHT": "Combat"
    },
    "BUTTON": {
      "SIGN_UP" : "Créer un compte",
  	  "LOGIN": "Se connecter",
  	  "LOG_OUT": "Se déconnecter",
  	  "SEARCH": "Rechercher",
  	  "RESET": "RàZ ?",
      "RESET_FILTER" : "Effacer les filtres",
  	  "ADD_TO_FIGHT":"Ajouter à un combat",
      "REMOVE_FROM_FIGHT":"Supprimer du combat",
  	  "CONTACT_MANAGER":"Contacter le manager",
      "CONTACT_HAS_NO_MANAGER":"Ce combattant n'a pas de manager",
  	  "CONTACT_FIGHTER":"Contacter le combattant",
  	  "MODIFY_FIGHT_CARD":"Modifer cette fightcard",
  	  "ADD_FIGHTER_TO_FIGHT":"Ajouter un combattant (optionnel)",
  	  "DELETE":"Supprimer",
      "EDIT" : "Modifier",
  	  "CLOSE": "Fermer",
      "ADD_FIGHT" : "Ajouter un combat",
      "SHOW_FIGHTLIST" : "Afficher les combats",
      "SAVE" : "Enregistrer",
      "YES" : "Oui",
      "NO" : "Non",
      "ADD" : "Ajouter",
      "CONTACT" : "Contacter",
      "SEND_MAIL" : "Envoyer un mail",
      "SEND_MESSAGE" : "Envoyer un message",
      "SEE_FIGHT" : "Voir les combats",
      "REPLACE_FIGHTER" : "Remplacer",
      "CANCEL" : "Annuler",
      "SEND" : "Envoyer",
      "sending" : "Envoi en cours ...",
      "sent" : "Envoyée",
      "MAIL_MANAGER": "Envoyer un e-mail au manager",
      "MAIL_MANAGER_AND_FIGHTER": "Envoyer un e-mail au manager et au combatant",
      "MESSAGE_MANAGER": "Envoyer un message au manager",
      "MESSAGE_FIGHTER": "Envoyer un message au combatant",
      "CONFIRM_CHANGING" : "Valider le changement",
      "PUBLISH" : "Publier",
      "UNPUBLISH" : "Dépublier",
      "ADD_YOUR_LOGO":"Ajouter votre logo",
      "FIGHT_FOLDER" : "Voir le dossier de combat",
      "SHOW_LICENCE" : "Voir la license",
      "REFUSE" : "Refuser",
      "CREATE_FIGHTER" : "Créer un combattant",
      "SEND_MESSAGE_ALL_FIGHTERS" : "Message groupé",
      "ADD_ATTACHEMENT" : "Ajouter une pièce jointe",
      "cancelSubscription" : "Résilier mon abonnement",
      "unsubscribing":"Résiliation en cours ...",
      "activate" : "Activer",
      "activated" : "Activé",
      "activation" : "Activation",
      "unsubscribe" : "Résilier",
      "deleteAccount" : "Supprimer le compte",
      "go_subscribe" : "J'en profite",
      "FILTER" : "Filtrer",
      "picture" : "Photo",
      "start" : "Démarrer",
      "next":"Suivant",
      "prev" : "Précédent",
      "write" : "Ecrire",
      "MORE_FILTERS" : "+ de critères",
      "ADD_TO_THIS_FIGHT" : "Ajouter au combat",
      "viewSubscription" : "Voir les abonnements",
      "createFight" : "Créer une offre de combat",
      "selectFighter" :"Sélectionner un combattant",
      "createFightcard" : "Créer une fight card"
    },
    "timeAgo": {
  	  "now" : "Maintenant",
      "day" : "j",
      "minute" : "min",
      "hour" : "h",
      "yesterday" : "Hier",
      "tomorrow" : "Demain",
    },
    "dates" : {
      "monthShortNames" : "Jan, Fév, Mar, Avr, Mai, Jui, Jui, Aoû, Sep, Oct, Nov, Déc",
      "jan" : "Janvier",
      "feb" : "Février.",
      "march" : "Mars",
      "apr" : "Avril",
      "may" : "Mai",
      "jun" : "Juin",
      "jully" : "Juillet",
      "aug" : "Août",
      "sept" : "Septembre",
      "oct" : "Octobre",
      "nov" : "Novembre",
      "dec" : "Décembre"
    },
    "paypalTranscationState" : {
      "authorisation" : "AUTHORISATION EN COURS...",
      "canceled" : "ABANDONNEE",
      "approved" : "TRANSACTION VALIDEE",
      "completed" : "ENREGISTREMENT DE LA TRANSACTION"
    }
}
